import { lightColors, darkColors} from './colors';

interface FontFeatures {
    fontSize: number,
    fontImportUrl:string,
    fontFamily:string,
}

const theme = (
    themeName:string="light",
    font: FontFeatures,
    customScss?: string
) => {
    const themes:any = {
        "light": lightColors,
        "dark": darkColors
    };
    return {
        ...themes[themeName],
        font: {
            fontSize: font.fontSize,
            fontImportUrl: font.fontImportUrl,
            fontFamily: font.fontFamily
        }, 
        customScss: customScss
    };
}

export default theme;